import React, {useEffect} from 'react';
import {navigate} from "gatsby";

const CreateAccount = () => {
    useEffect(() => {
       navigate('/');
    }, []);

    return (<div></div>);
};
export default CreateAccount;
